<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search  :searchList="$myMessageSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <div class="bg-white" style="height: 100%;">
        <el-table  ref="mainTable" height="calc(100% - 52px)" :key='tableKey' :data="list"  border fit highlight-current-row style="width: 100%;" @row-click="rowClick"  @selection-change="handleSelectionChange">
          <el-table-column min-width="80px" :label="'消息分类'">
            <template slot-scope="scope">
              <span>{{scope.row.typeName}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="80px" :label="'发送人'">
            <template slot-scope="scope">
              <span>{{scope.row.fromName}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="80px" :label="'状态'">
            <template slot-scope="scope">
              <el-tag  v-if="scope.row.toStatus ==0">未读</el-tag>
              <el-tag type="success" v-if="scope.row.toStatus ==1">已读</el-tag>
              <el-tag type="danger" v-if="scope.row.toStatus ==-1">已删</el-tag>
            </template>
          </el-table-column>
          <el-table-column min-width="180px" :label="'消息内容'">
            <template slot-scope="scope">
              <span>{{scope.row.content}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="80px" :label="'消息时间'">
            <template slot-scope="scope">
              <span>{{scope.row.createTime}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="'操作'" width="250">
            <template slot-scope="scope" >
              <div class="center-button">
              <el-button type="primary" size="mini"  v-if="scope.row.toStatus ==0" @click="handleRead(scope.row)">标记已读</el-button>
              <el-button type="danger" size="mini" @click="handleDelete([scope.row])">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
      </div>
    </div>
    <!-- 发送信息 -->
			<el-dialog   v-el-drag-dialog class="dialog-mini" v-loading="listLoading" width="80%" height="500px" title="选择操作员" :visible.sync="showMessage"  @close='closeDialog'>
				<div class="tabParset" >
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="公司" :name="0">公司</el-tab-pane>
            <el-tab-pane label="部门" :name="1">部门</el-tab-pane>
            <el-tab-pane label="角色" :name="2">角色</el-tab-pane>
            <el-tab-pane label="个人" :name="3">个人</el-tab-pane>
          </el-tabs>
          <div>
            <el-input type="textarea"  placeholder="请输入内容"  v-model="textarea" maxlength="500" show-word-limit rows="7" > </el-input>
          </div>
        </div>
        <div style="text-align:right;" slot="footer">
            <el-button size="small" type="danger" @click="closeDialog">取消</el-button>
            <el-button size="small" type="primary" @click="sendMessage">确定</el-button>
        </div>
			</el-dialog>
  </div>
 
</template>

<script>
  
  import * as sysMessages from '@/api/sysmessages'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import Pagination from '@/components/Pagination'
  import elDragDialog from '@/directive/el-dragDialog'
  import extend from "@/extensions/delRows.js"
  export default {
    name: 'sysMessage',
    components: {
      Sticky,
      Pagination,
    },
    directives: {
      waves,
      elDragDialog
    },
    mixins: [extend],
    data() {
      return {
        textarea:"",
        activeName:0,
        showMessage:false,
        multipleSelection: [], // 列表checkbox选中的值
        tableKey: 0,
        list: [],
        total: 0,
        listLoading: true,
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          status: 999, //999为全部，0为未读，1为已读
          key: undefined
        }
      }
    },
    filters: {
    },
    created() {
      if(this.$route.query.messageId){
        this.listQuery.key =this.$route.query.messageId
      }
    },
    activated() {
      this.getList()
    },
    methods: {
      closeDialog(){
        this.showMessage=false
      },
      sendMessage(){
        alert("发送成功")
      },
      handleClick(){
      },
      rowClick(row) {
        this.$refs.mainTable.clearSelection()
        this.$refs.mainTable.toggleRowSelection(row)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      onBtnClicked: function (domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnEdit':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleRead(this.multipleSelection[0])
            break
          case 'btnDel':
            if (this.multipleSelection.length < 1) {
              this.$message({
                message: '至少删除一个',
                type: 'error'
              })
              return
            }
            this.handleDelete(this.multipleSelection)
            break
          case 'btnMessage':
            this.showMessage=true
            break
          default:
            break
        }
      },
      getList() {
        this.listLoading = true
        sysMessages.getList(this.listQuery).then(response => {
          this.list = response.data
          this.total = response.count
          this.listLoading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      handleRead(row) { //标记已读
      this.$store.commit("updataListLoading",true)
        sysMessages.read({
          id: row.id
        }).then(() => {
          for (const v of this.list) {
            if (v.id === row.id) {
              v.toStatus = 1 //标记界面已读
              break
            }
          }
          this.$store.commit("updataListLoading",false)
        }).catch(()=>{
          this.$store.commit("updataListLoading",false)
        })
      },
      handleDelete(rows) { // 逻辑删除
        this.delrows(sysMessages, rows,()=>{this.getList()})
      }
    }
  }
</script>
<style>
  .center-button{
    text-align: center;
  }
  .dialog-mini .el-select {
    width: 100%;
  }
 
</style>
<style scoped lang="scss">
 .tabParset{
   width: 90%;
 }
</style>
