var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              attrs: {
                searchList: _vm.$myMessageSearch,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c(
          "div",
          { staticClass: "bg-white", staticStyle: { height: "100%" } },
          [
            _c(
              "el-table",
              {
                key: _vm.tableKey,
                ref: "mainTable",
                staticStyle: { width: "100%" },
                attrs: {
                  height: "calc(100% - 52px)",
                  data: _vm.list,
                  border: "",
                  fit: "",
                  "highlight-current-row": "",
                },
                on: {
                  "row-click": _vm.rowClick,
                  "selection-change": _vm.handleSelectionChange,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": "80px", label: "消息分类" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.typeName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "80px", label: "发送人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.fromName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "80px", label: "状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.toStatus == 0
                            ? _c("el-tag", [_vm._v("未读")])
                            : _vm._e(),
                          scope.row.toStatus == 1
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("已读"),
                              ])
                            : _vm._e(),
                          scope.row.toStatus == -1
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("已删"),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "180px", label: "消息内容" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "80px", label: "消息时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", width: "250" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "center-button" },
                            [
                              scope.row.toStatus == 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRead(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("标记已读")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete([scope.row])
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.page,
                limit: _vm.listQuery.limit,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "page", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "limit", $event)
                },
                pagination: _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          directives: [
            { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "dialog-mini",
          attrs: {
            width: "80%",
            height: "500px",
            title: "选择操作员",
            visible: _vm.showMessage,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMessage = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "tabParset" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "公司", name: 0 } }, [
                    _vm._v("公司"),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "部门", name: 1 } }, [
                    _vm._v("部门"),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "角色", name: 2 } }, [
                    _vm._v("角色"),
                  ]),
                  _c("el-tab-pane", { attrs: { label: "个人", name: 3 } }, [
                    _vm._v("个人"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入内容",
                      maxlength: "500",
                      "show-word-limit": "",
                      rows: "7",
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.sendMessage },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }